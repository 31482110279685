<template>

  <div>
    <div class="contents">
      <div class="uk-background-center-center parallax-bg" >
        <h2 class="uk-text-center page-title uk-padding-small">
          International Logistics<br>
          国際物流
        </h2>
        <div class="section-main">
          <span>
            {{$_chlang(logistics)}}
          </span>
        </div>
        <mini-manu />
      </div>
    </div>
  </div>
  
</template>

<script>
import CompanyInfo from '../../mixins/company-info.yaml'
import miniManu from'../modules/parts/MiniMenu.vue'

export default {
  name: 'JigyoLogistics',
  components: {
    miniManu
  },
  data: () => ({
    logistics: []
  }),
  created() {
    this.logistics = CompanyInfo.logistics
  },
}
</script>

<style scoped lang="scss">

.parallax-bg {
  background-image:url(~@/assets/img/image/sub-links-jigyo-logistics.jpg);
}

h2{
  color: #fff;
}
span{
  white-space: pre-line;
}
</style>
